<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <parte-form
          v-if="dbReady"
          :id="routeParams.idparte_trabajo"
          :has-perm-modificar-mano-obra-facturable="hasViewPerm(permissions.partes.modificarManoObraFacturable)"
          :has-perm-modificar-desplazamiento-facturable="hasViewPerm(permissions.partes.modificarDesplazamientoFacturable)"
          :has-perm-modificar-fechas-planificacion="hasViewPerm(permissions.partes.modificarFechasPlanificacion)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ParteForm from './components/ParteForm'
import Data from './ParteEditData'

export default {
  components: {
    ParteForm
  },
  mixins: [formPageMixin],
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = `Modificar parte Nº${this.routeParams.idparte_trabajo}`
    },
    async submitForm () {
      // caso especial de campos fecha -> convertir a Date si se modificaron
      // no es necesario, B10DateTimePicker siempre devuelve Date
      await Data.update(this, this.routeParams.idparte_trabajo, this.formData)
      await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajo, this.routeParams.idparte_trabajo)
      this.$appRouter.go(-1)
    },
  },
}
</script>
